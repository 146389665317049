<div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header"
            [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
            <div></div>
            <div class="modalHeaderTitle">
                {{ initialState && initialState.popupTitle | translate}}
            </div>
            <button class="btn"
                [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
                (click)="onCloseModalWindowClick()">
                <span class="icon icon-cancel"></span>
            </button>
        </div>

        <form *ngIf="initialState" #addNewClientForm="ngForm" (ngSubmit)="onSubmitClientClick(addNewClientForm)"
            novalidate>
            <div class="modal-body pt-2 mb-2">
                <div class="row mt-p5 AI_C">
                    <div class="col-md-3 col-lg-3 TA_R">
                        {{ "Company Name" | translate }} <sup class="redColor"> *</sup>:
                    </div>
                    <div class="col-md-8 col-lg-8 col-lg-8">
                        <input class="W-100P boxShadow blackColor" type="text" name="companyName"
                            placeholder="{{'Enter'|translate}} {{'Company' | translate}} {{'Name' | translate}}" ngModel
                            #companyName="ngModel" [(ngModel)]="clientModel.companyName"
                            [class.disabled]="!initialState.isAddClientWindow"
                            [disabled]="!initialState.isAddClientWindow" (input)="allowOnlySpace(companyName)" />
                        <ng-container *ngIf="companyName.invalid">
                            <div class="errorField validation-errormsg">
                                {{ companyName.errors?.['error'] }}
                            </div>
                        </ng-container>
                    </div>
                    <div class="col-md-1 col-lg-1"></div>
                </div>
                <div class="row mt-p5 AI_C">
                    <div class="col-md-3 col-lg-3 TA_R">
                        {{ "Sender Email" | translate }} <sup class="redColor"> *</sup>:
                    </div>
                    <div class="col-md-8 col-lg-8 col-lg-8">
                        <input class="W-100P boxShadow blackColor" type="email" name="senderEmail" ngModel
                            #senderEmail="ngModel" [(ngModel)]="clientModel.senderEmail"
                            placeholder="{{'Enter'|translate}} {{ 'Sender' | translate }} {{'Email' | translate }}"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$" required />
                        <div class="errorField validation-errormsg"
                            *ngIf="senderEmail.errors?.['touched'] || senderEmail.errors?.['dirty'] || senderEmail.errors?.['pattern'] || senderEmail.errors?.['invalid']">
                            {{ "ERRORS.Please provide a valid email address" | translate }}
                        </div>
                    </div>
                    <div class="col-md-1 col-lg-1"></div>
                </div>

                <div class="row AI_C mt-p5" [class.clsbranddetails]="clientModel.brand.length > 0">
                    <div class="col-md-3 col-lg-3 TA_R">
                        {{ "Brand Details :" | translate }}
                    </div>
                    <div class="col-md-8 col-lg-8">
                        <button *ngIf="clientModel.brand.length == 0" type="button" class="cr-pointer btn btn-default"
                            [disabled]="!(clientModel.companyName && clientModel.senderEmail)"
                            [class.disabled]="!(clientModel.companyName && clientModel.senderEmail)"
                            [title]="(clientModel.companyName && clientModel.senderEmail) ? '': ('Please enter required fields first.' | translate)"
                            (click)="onAddNewBrand()">
                            <label class="selectaLabel">
                                <strong>+</strong>
                            </label>
                        </button>
                    </div>
                </div>
                <div class="row mt-p5" *ngFor="let brand of clientModel.brand; let bIndex = index">
                    <div class="col-md-1 col-lg-1"></div>
                    <div class="col-md-10 col-lg-10">
                        <div class="D-F JC_SB gap-2">
                            <div>
                                <input class="boxShadow H-35 blackColor" type="text"
                                    placeholder="{{'Brand Name' | translate}} *" name="brandName_{{bIndex}}" ngModel
                                    #brandName="ngModel" [(ngModel)]="brand.brandName"
                                    [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    (input)="checkUniqueInput(brandName, bIndex)" required />
                                <span *ngIf="brandName.invalid" class="errorField">
                                    {{ brandName.errors?.['error'] }}
                                </span>
                            </div>
                            <div>
                                <input class="boxShadow H-35 blackColor" type="text"
                                    placeholder="{{'Brand Short' | translate}} *" name="brandShort_{{bIndex}}" ngModel
                                    #brandShort="ngModel" [(ngModel)]="brand.brandShort"
                                    [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    (input)="checkUniqueInput(brandShort, bIndex, 'brandShort')" required />
                                <span *ngIf="brandShort.invalid" class="errorField">
                                    {{ brandShort.errors?.['error'] }}
                                </span>
                            </div>
                            <div>
                                <input class="boxShadow H-35 blackColor" placeholder="{{'Brand Code' | translate}} *"
                                    type="text" name="brandCode_{{bIndex}}" ngModel #brandCode="ngModel"
                                    [(ngModel)]="brand.brandCode" validateNumber="4"
                                    [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    (input)="checkUniqueInput(brandCode, bIndex, 'brandCode')" required />
                                <span *ngIf="brandCode.invalid" class="errorField">
                                    {{ brandCode.errors?.['error'] }}
                                </span>
                            </div>
                            <div class="D-F gap-1">
                                <button type="button" class="btn btn-default h-2p3rem"
                                    [class.disabled]="(clientModel.brand.length-1 !== bIndex || addNewClientForm.invalid)"
                                    [disabled]="(clientModel.brand.length-1 !== bIndex || addNewClientForm.invalid)"
                                    (click)="onAddNewBrand()">
                                    <strong>+</strong>
                                </button>
                                <button type="button" class="btn btn-default h-2p3rem"
                                    (click)="onRemoveBrand(bIndex)"
                                    [class.disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount"
                                    [disabled]="!initialState.isAddClientWindow && bIndex < existingBrandsCount">
                                    <strong class="redColor">-</strong>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer" *ngIf="accessControls.isCreate">
                <button class="btn btn-primary" [ngStyle]="{ 'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor,
                    color: applyThemeService.$textColor}" [class.disabled]="addNewClientForm.invalid" [disabled]="addNewClientForm.invalid">
                    {{"BUTTONS.Submit" |translate }}
                </button>
            </div>
        </form>

    </div>
</div>