import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as XLSX from "xlsx";

import { DropdownOptionsSettingsService } from 'src/app/shared/services/dropdown-options-settings';
import { StateManagementService } from 'src/app/shared/services/state.service';
import { MessagesService } from 'src/app/shared/components/messages/messages.service';
import { One2FiveLoadingService } from 'src/app/shared/components/app-loader/loader.service';
import { AccessControlService } from 'src/app/core/services/access-control.service';
import { CommonUtilService } from 'src/app/core/services/common-util.service';
import { ApplyThemeService } from 'src/app/shared/services/apply-theme.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MessageItemType } from 'src/app/shared/components/messages/message.model';
import { AccessControls } from 'src/app/core/models/user.permission.model';
import { ReviewUploadResponse } from 'src/app/modules/review-management/models/review-upload.model';
import { AppLocalStoreService } from 'src/app/shared/services/app-store.service';
import { One2FiveHelperService } from 'src/app/shared/services/one2five-helper.service';

enum EnumModerationStatus { Pending, Approved }

@Component({
  selector: 'app-review-upload',
  templateUrl: './review-upload.component.html',
  styleUrls: ['./review-upload.component.scss']
})
export class ReviewUploadComponent implements OnChanges {
  @Input("ready2LoadWindow") ready2LoadWindow: boolean = false;

  accessControls = new AccessControls;
  reviewStatus: any[] = AppLocalStoreService.getApprovedPendingStatus();
  reviewSelectedStatuses: any = [];
  moderationStatusDropdownSettings = {};

  badgeSettings = {};
  badgeSelectedList: any[] = [];
  badgeTemplateList: any[] = [];

  arrMarketList: any[] = [];
  brandNameOriginalList: any[] = [];
  marketListSelectedItems: any[] = [];
  marketDropdownSettings = {};
  selectedMarkets: any[] = [];

  file!: File;
  moderationStatus = EnumModerationStatus;
  isFileValid: boolean = false;

  constructor(private translate: TranslateService,
    public applyThemeService: ApplyThemeService,
    private accessControlService: AccessControlService,
    private dataService: DataService,
    private stateService: StateManagementService,
    private one2FiveHelper: One2FiveHelperService,
    public commonUtilService: CommonUtilService) {
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (!this.ready2LoadWindow) return;

    this.accessControls = this.accessControlService.configAccess(ConstantsService.REVIEW_UPLOAD);
    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.translate.use(element);
      this.changeTranslationWords();
    });

    this.marketDropdownSettings = DropdownOptionsSettingsService.getBrandSiteSettings();
    this.badgeSettings = DropdownOptionsSettingsService.getBadgeSettings();
    this.moderationStatusDropdownSettings = DropdownOptionsSettingsService.getModerationStatusSettings();

    this.getBrandSite();
    this.getBadgesForReview();
  }

  getBrandSite() {
    this.arrMarketList = [];
    this.marketListSelectedItems = [];
    One2FiveLoadingService.show();
    this.stateService.getCountrySelect().subscribe((data: any) => {
      this.arrMarketList = data;
      this.brandNameOriginalList = data;
    }, (error: any) => {
      MessagesService.errorMessage(error);
      One2FiveLoadingService.hide();
    });
  }

  onBrandSearchKeyUp(evt: any) {
    this.arrMarketList = this.brandNameOriginalList.filter(element => element.itemName.toString().toUpperCase().includes(evt.target.value.toString().toUpperCase()));
  }

  getBadgesForReview() {
    One2FiveLoadingService.show();
    this.dataService.getbadges().subscribe((_response: any) => {
      this.badgeTemplateList = _response.badgeTemplateList;
      One2FiveLoadingService.hide();
    }, (error: any) => {
      MessagesService.errorMessage(error);
      One2FiveLoadingService.hide();
    });
  }

  onBadgeSelect(selectedBadge: any): void {
    if (selectedBadge.badgeName === "Sweepstake") {
      this.removeItemFromArray(this.badgeSelectedList, "badgeName", "Incentivized");
    } else if (selectedBadge.badgeName === "Incentivized") {
      this.removeItemFromArray(this.badgeSelectedList, "badgeName", "Sweepstake");
    }
  }

  removeItemFromArray(arr: any, attr: any, value: any): any {
    let i = arr.length;
    while (i--) {
      if (arr[i] && arr[i].hasOwnProperty(attr) && arguments.length > 2 && arr[i][attr] === value) {
        arr.splice(i, 1);
      }
    }
    return arr;
  }

  onSelectFile(event: any) {
    this.validateFile(event.target.files);
    this.parseXlxs(event.target.files);
  }

  dropped(target: any) {
    this.validateFile(target);
    this.parseXlxs(target);
  }

  parseXlxs(target: any) {
    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: "binary" });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      this.file = target[0];
    };
    reader.readAsBinaryString(target[0]);
  }

  async validateFile(files: any) {
    if (files.length !== 1) {
      throw new Error(this.commonUtilService.translateMessage("Cannot use multiple files"));
    }

    const validExts = [".xlsx", ".xls"];
    let fileExt = files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf("."));

    if (validExts.indexOf(fileExt) < 0) {
      this.isFileValid = false;
      MessagesService.alertMessages("Please upload valid file", "", "Validation Alert", MessageItemType.Warning);
      let invalidMessage = await this.commonUtilService.transalateInputText("Invalid file selected, valid files are of ") + validExts.toString();
      invalidMessage += await this.commonUtilService.transalateInputText(" types");
      throw new Error(invalidMessage);
    } else {
      this.isFileValid = true;
    }
  }

  reUpload() {
    this.isFileValid = false;
  }

  validateForm(): boolean {
    if (!this.reviewSelectedStatuses.length) {
      MessagesService.alertMessages("ERRORS.Please select at least one moderation status", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    if (!this.marketListSelectedItems.length) {
      MessagesService.alertMessages("ERRORS.Please select at least one market", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    if (!this.badgeSelectedList.length) {
      MessagesService.alertMessages("ERRORS.Please select at least one badge", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    if (!this.file) {
      MessagesService.alertMessages("ERRORS.Please upload file", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    return true;
  }

  uploadFile() {
    if (!this.validateForm()) return;

    const postData = new FormData();
    postData.append("brandsite", this.marketListSelectedItems[0].regionName);
    postData.append("badges", this.badgeSelectedList.map((item) => item.badgeName).toString());
    postData.append("moderationStatus", this.reviewSelectedStatuses[0].itemName);
    postData.append("uploadedReviewFile", this.file);

    One2FiveLoadingService.show();
    this.dataService.uploadReviewXls(postData).subscribe((responseData: ReviewUploadResponse) => {
      One2FiveLoadingService.hide();
      if (responseData["HasErrors"]) {
        MessagesService.errorAlert(responseData["Errors"][0].Message);
        return;
      }
      this.one2FiveHelper.isCloseModalWindow(true);
      let messages: string[] = [`Imported Review Count : ${responseData.importedReviewCount}`,
      `Skipped Review Count : ${responseData.skippedReviewCount}`,
      `Typical Hours To Post : ${responseData.TypicalHoursToPost}`];
      MessagesService.successDetailsMessages(messages);
    });
  }

  onCloseReviewUplodWindow() {
    this.one2FiveHelper.isCloseModalWindow(true);
  }

  private async changeTranslationWords() {
    const translatedSearchLabel = await this.commonUtilService.transalateInputText("Search");
    const selectAllLabel = await this.commonUtilService.transalateInputText("DROPDOWNS.Select All");
    const unSelectAllLabel = await this.commonUtilService.transalateInputText("DROPDOWNS.UnSelect All");

    let brandSitePlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Brand Site");
    this.marketDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.marketDropdownSettings, brandSitePlaceholderText, translatedSearchLabel, selectAllLabel, unSelectAllLabel);

    let badgesPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Badges");
    this.badgeSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.badgeSettings, badgesPlaceholderText, translatedSearchLabel, selectAllLabel, unSelectAllLabel);

    let moderationStatusPlaceholderText = await this.commonUtilService.transalateInputText("PLACEHOLDERS.Select Moderation Status");
    this.moderationStatusDropdownSettings = DropdownOptionsSettingsService.translateDDOptionLabels(this.moderationStatusDropdownSettings, moderationStatusPlaceholderText, translatedSearchLabel, selectAllLabel, unSelectAllLabel);
  }

}
