<div *ngIf="applyThemeService.$applyTheme">
  <div class="headerContainer background-box-shadow">
    <app-header></app-header>
  </div>
  <div class="row">
    <div [ngStyle]="{ 'background-color': applyThemeService.$themeColor }"
      class="col-md-2 col-sm-2 card-right-box-shadow PR-0">
      <app-menubar></app-menubar>
    </div>
    <div class="col-md-10 col-sm-10 col-lg-10 overflowScroll">
      <router-outlet></router-outlet>
    </div>
  </div>
</div>