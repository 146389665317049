<div class="modal-dialog modal-md modal-lg">
  <div class="modal-content">
    <div class="modal-header"
      [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
      <div></div>
      <div class="modalHeaderTitle">
        {{ 'Edit Profile' | translate}}
      </div>
      <button class="btn"
        [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
        (click)="onCloseWindowClick()">
        <span class="icon icon-cancel"></span>
      </button>
    </div>

    <div class="modal-body p-4">
      <div class="row mt-p5 AI_C">
        <div class="col-md-1"></div>
        <div class="col-md-2 TA_R">
          <label for="name">{{"Name" | translate}} :</label>
        </div>
        <div class="col-md-8">
          <div class="d-flex gap-2">
            <select class="form-select cr-pointer salutation" name="" [(ngModel)]="userProfile.salutation">
              <option class="salutationOptions" value="Mr" selected>{{"Mr"|translate}}</option>
              <option class="salutationOptions" value="Mrs">{{"Mrs"|translate}}</option>
              <option class="salutationOptions" value="Ms">{{"Ms"|translate}}</option>
            </select>
            <input class="form-control w-100" type="text" name="" placeholder="{{'First'|translate}} {{'Name' | translate}}"
              [(ngModel)]="userProfile.firstName" />
            <input class="form-control w-100" type="text" name="" placeholder="{{'Last'|translate}} {{'Name' | translate}}"
              [(ngModel)]="userProfile.lastName" />
          </div>
        </div>
      </div>

      <div class="row mt-p5 AI_C">
        <div class="col-md-1"></div>
        <div class="col-md-2 TA_R">
          <label for="nickname ">{{"Nickname" | translate}} :</label>
        </div>
        <div class="col-md-8">
          <input class="form-control" type="text" name="" placeholder="{{'Nickname'|translate}}"
            [(ngModel)]="userProfile.userNickname" />
        </div>
      </div>

      <div class="row mt-p5 AI_C">
        <div class="col-md-1"></div>
        <div class="col-md-2 TA_R">
          <label for="email">{{"Email" | translate}} <span class="required">*</span> :</label>
        </div>
        <div class="col-md-8">
          <input class="form-control" type="email" name="" placeholder="{{'Email'|translate}} {{'Address'|translate}}"
            [(ngModel)]="userProfile.emailId" />
        </div>
      </div>

      <div class="row mt-p5 AI_C">
        <div class="col-md-3 TA_R">
          <label for="Company_Name">{{"Company" | translate}} {{"Name" | translate}} :</label>
        </div>
        <div class="col-md-8">
          <label class="F_B">Electrolux</label>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="onFileUploadClick()" [disabled]="!userProfile.emailId"
        [class.disabled]="!userProfile.emailId" [ngStyle]="{ 'background-color': applyThemeService.$themeColor,
        'border-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
        {{"BUTTONS.Submit" | translate}}
      </button>
    </div>
  </div>
</div>