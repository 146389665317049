import { Output, Component, EventEmitter, Input, OnChanges } from "@angular/core";

import { DataService } from "../../services/data.service";
import { TranslateService } from "@ngx-translate/core";
import { ApplyThemeService } from "../../services/apply-theme.service";
import { CommonUtilService } from "src/app/core/services/common-util.service";
import { One2FiveUtils } from "src/app/core/services/one2five-utils";
import { ThemeTemplateModel } from "../../models/theme.model";
import { ChangePassword } from "./change-password.model";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnChanges {
  @Input("isReady2Load") isReady2Load: boolean = false;
  @Output("onCloseWindow") onCloseWindow = new EventEmitter();
  @Output("success") success = new EventEmitter<boolean>();
  errorMessage: string = "";
  changePassword = new ChangePassword;

  themeSettings: ThemeTemplateModel = new ThemeTemplateModel;
  isIncorrectOldPassword: boolean = false;

  constructor(private dataService: DataService,
    public applyThemeService: ApplyThemeService,
    private translate: TranslateService,
    public commonUtilService: CommonUtilService) {
  }

  ngOnChanges() {
    if(!this.isReady2Load) return;

    this.isIncorrectOldPassword = false;
    this.changePassword = new ChangePassword;
    this.errorMessage = "";
    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.translate.use(element);
    });
    this.applyThemeService.loadNewTheme$.subscribe((_theme: any) => {
      if (!_theme) return;
      this.themeSettings = One2FiveUtils.objectClone(_theme);
    });
  }

  onCloseWindowClick() {
    this.onCloseWindow.emit({});
  }

  validateFields(): boolean {
    this.errorMessage = "";
    if (this.changePassword.oldPassword === "" || this.changePassword.oldPassword == null) {
      this.errorMessage = "ERRORS.Please enter old password";
      return false;
    } else if (this.changePassword.newPassword === "" || this.changePassword.newPassword == null) {
      this.errorMessage = "ERRORS.Please enter new password";
      return false;
    } else if (this.changePassword.oldPassword === this.changePassword.newPassword) {
      this.errorMessage = "ERRORS.New Password must be different from Old password";
      return false;
    } else if (this.changePassword.newPassword.length < 6) {
      this.errorMessage = "ERRORS.New Password must contain atleast six characters";
      return false;
    } else if (!/[0-9]/.test(this.changePassword.newPassword)) {
      this.errorMessage = "ERRORS.New Password must contain atleast one number (0-9)";
      return false;
    } else if (!/[a-z]/.test(this.changePassword.newPassword)) {
      this.errorMessage = "ERRORS.New Password must contain atleast one lowercase letter (a-z)";
      return false;
    } else if (!/[A-Z]/.test(this.changePassword.newPassword)) {
      this.errorMessage = "ERRORS.New Password must contain atleast one uppercase letter (A-Z)";
      return false;
    } else if (this.changePassword.confirmPassword === "" || this.changePassword.confirmPassword == null) {
      this.errorMessage = "ERRORS.Please enter confirm password";
      return false;
    } else if (this.changePassword.newPassword !== this.changePassword.confirmPassword) {
      this.errorMessage = "ERRORS.Please confirmed your password";
      return false;
    }
    return true;
  }

  onSubmitChangePassword() {
    if (!this.validateFields()) return;

    this.dataService.changePassword(this.changePassword).subscribe((data: any) => {
      if (data["HasErrors"]) {
        this.isIncorrectOldPassword = true;
        this.changePassword.newPassword = "";
        this.changePassword.confirmPassword = "";
        return;
      }
      this.success.emit(true);
      this.onCloseWindowClick();
    }, async (_error: any) => {
      this.errorMessage = await this.commonUtilService.transalateInputText("ERRORS." + _error);
      this.commonUtilService.showLoader = false;
    });
  }

}
