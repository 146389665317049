import { Injectable } from "@angular/core";

import { One2FiveUtils } from "src/app/core/services/one2five-utils";
import { COMMON_SINGLE_DROPDOWN_SETTINGS, MULTI_DROPDOWN_SETTINGS, SINGLE_DROPDOWN_SETTINGS } from "../constants/constants";

@Injectable({ providedIn: "root" })
export class DropdownOptionsSettingsService {

    constructor() { }

    static translateDDOptionLabels(settingsObject: any, placeholderText: string, searchText: string = "", selectAllText: string = "", unSelectAllText: string = "") {
        const ddOptionSettings = One2FiveUtils.objectClone(settingsObject);
        ddOptionSettings["text"] = placeholderText;
        if (searchText) {
            ddOptionSettings["searchPlaceholderText"] = searchText;
        }
        if (selectAllText) {
            ddOptionSettings["selectAllText"] = selectAllText;
        }
        if (unSelectAllText) {
            ddOptionSettings["unSelectAllText"] = unSelectAllText;
        }
        return ddOptionSettings;
    }

    static getSelectProductSettings() {
        return {
            singleSelection: true,
            text: "Select Product",
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            lazyLoading: true,
            badgeShowLimit: 1,
            maxHeight: 200
        };
    }

    static getSSProductSettings() {
        return {
            singleSelection: true,
            text: "Select product",
            enableSearchFilter: true,
            classes: "ddCustomClass",
            lazyLoading: true,
        };
    }

    static getProductSettings(enableCheckAll: boolean = false) {
        return {
            singleSelection: false,
            text: "Select Product",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            enableCheckAll: enableCheckAll,
            classes: "ddCustomClass custom-class",
            lazyLoading: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            tagToBody : false
        };
    }

    public static getAllProductsSettings() {
        return {
            ...COMMON_SINGLE_DROPDOWN_SETTINGS,
            text: "All Products",
            searchBy: ['text'],
            labelKey: 'text',
            searchPlaceholderText: "Product Id",
            noDataLabel: "No Products Available",
            primaryKey: 'id'
        }
    }

    public static getCategoriesSettings(enableCheckAll: boolean = false) {
        return {
            singleSelection: false,
            text: "Select Categories",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            enableCheckAll: enableCheckAll,
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true
        };
    }
    public static getCampaignsSettings() {
        return {
            singleSelection: false,
            text: "Select Campaigns",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: 'ddCustomClass custom-class',
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true
        };
    }

    public static getReviewOriginSettings() {
        return {
            singleSelection: false,
            text: "Select Review Origin",
            selectAllText: "Select All",
            unSelectAllText: "Unselect All",
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            maxHeight: 200
        };
    }

    public static getSSSettings() {
        return {
            singleSelection: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true
        };
    }

    public static getSSIntervalSettings() {
        return {
            singleSelection: true,
            text: "Select Interval",
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true
        };
    }

    public static getSSMarketSettings() {
        return {
            singleSelection: true,
            text: "Select Market",
            primaryKey: "itemName",
            enableSearchFilter: true,
            classes: "ddCustomClass",
            lazyLoading: true
        };
    }

    public static getMarketSourceSettings() {
        return {
            singleSelection: true,
            text: "Select Market Source",
            labelKey: "itemName",
            primaryKey: "itemName",
            maxHeight: 80,
            lazyLoading: true
        }
    }

    public static getSSBadgeTypeSettings() {
        return {
            singleSelection: true,
            text: "Select Badge type",
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true
        };
    }

    public static getSSLanguageSettings() {
        return {
            singleSelection: true,
            text: "Select Language",
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true
        }
    }
    public static getLanguageWithPrimaryKeySettings() {
        return {
            singleSelection: true,
            text: "Select Language",
            labelKey: 'languageName',
            primaryKey: 'languageName',
            enableSearchFilter: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true,
            escapeToClose: true
        }
    }

    public static getTypeSettings() {
        return {
            singleSelection: true,
            text: "Select Type",
            labelKey: 'itemName',
            primaryKey: 'itemName',
            enableSearchFilter: false,
            badgeShowLimit: 1,
            maxHeight: 100,
            lazyLoading: true,
            escapeToClose: true
        };
    }
    public static getCountrySettings(primaryKey: string = 'code', labelKey: string = 'itemName') {
        return {
            primaryKey: primaryKey,
            text: "Select Country",
            badgeShowLimit: 1,
            maxHeight: 200,
            singleSelection: true,
            enableSearchFilter: true,
            lazyLoading: true,
            closeDropDownOnSelection: true,
            escapeToClose: true,
            searchBy: [labelKey],
            labelKey: labelKey
        }
    }
    public static getSSCountrySettings() {
        return {
            singleSelection: true,
            text: "Select Country",
            badgeShowLimit: 1,
            maxHeight: 200,
        }
    }

    public static getSSStatusSettings() {
        return {
            singleSelection: true,
            text: "Select Status",
            labelKey: "status",
            primaryKey: "status",
            enableSearchFilter: false,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            maxHeight: 80,
            lazyLoading: true
        };
    }
    public static getSSCustomerSettings() {
        return {
            singleSelection: true,
            text: "Select Customer",
            enableSearchFilter: false,
            badgeShowLimit: 1,
            classes: "ddCustomClass custom-class",
            maxHeight: 200,
            lazyLoading: true,
        };
    }

    public static getBadgeSettings() {
        return {
            singleSelection: false,
            primaryKey: "badgeTemplateId",
            labelKey: "badgeName",
            text: "Select Badges",
            enableCheckAll: false,
            enableSearchFilter: false,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true,
            tagToBody: false
        };
    }

    
    public static getModerationRuleSettings() {
        return {
            singleSelection: true,
            text: "Select Moderation Rule",
            enableSearchFilter: false,
            classes: 'ddCustomClass custom-class',
            badgeShowLimit: 1,
            disabled: true,
            lazyLoading: true,
            maxHeight: 150,
            primaryKey: 'id',
            labelKey: 'itemName',
            noDataLabel: "No Data Available"
        };
    }

    public static getModerationStatusSettings(text: string = "Select Moderation Status") {
        return {
            singleSelection: true,
            text: text,
            enableCheckAll: false,
            enableSearchFilter: false,
            badgeShowLimit: 1,
            classes: "ddCustomClass",
            escapeToClose: true,
            lazyLoading: true,
            maxHeight: 100,
            tagToBody: false,
            disabled: false
        };
    }


    public static getAllCompaniesSettings(enableCheckAll: boolean = true) {
        return {
            singleSelection: false,
            text: "All Companies",
            enableCheckAll: enableCheckAll,
            enableSearchFilter: true,
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            classes: 'ddCustomClass',
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true,
            escapeToClose: true,
            tagToBody: false
        };
    }

    public static getSingleSelectCompaniesSettings() {
        return {
            singleSelection: true,
            text: "All Companies",
            enableCheckAll: false,
            enableSearchFilter: false,
            classes: "ddCustomClass custom-class cls-companydd",
            badgeShowLimit: 1,
            lazyLoading: true
        };
    }
    
    public static getBrandSiteMultiSelect() {
        return {
            singleSelection: false,
            primaryKey: 'regionName',
            text: "All Brand Site",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableCheckAll: true,
            enableSearchFilter: true,
            classes: 'ddCustomClass',
            lazyLoading: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            escapeToClose: true,
            tagToBody: false
        }
    }

    public static getMultiSelectBrandSitesSettings() {
        return {
            singleSelection: false,
            primaryKey: 'regionName',
            text: "All Brand Sites",
            enableCheckAll: true,
            enableSearchFilter: true,
            classes: 'ddCustomClass custom-class',
            badgeShowLimit: 1,
            escapeToClose: true,
            lazyloading: true,
            disabled: false,
            noDataLabel: "No Data Available"
        };
    }

    public static getAllBrandSiteSettings() {
        return {
            singleSelection: true,
            primaryKey: "regionName",
            text: "All Brand Site",
            enableCheckAll: false,
            enableSearchFilter: false,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            lazyLoading: true
        };
    }

    public static getAllBrandSitesSettings() {
        return {
            singleSelection: true,
            primaryKey: "regionName",
            text: "All Brand Sites",
            enableCheckAll: false,
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            maxHeight: 200,
            enableFilterSelectAll: false,
            lazyLoading: true,
            searchBy: ['itemName']
        };
    }
   
    public static getBrandSitesSettings() {
        return {
            singleSelection: false,
            text: "All Brand Sites",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            lazyLoading: true,
            badgeShowLimit: 1
        }
    }

    public static getBrandSiteSettings() {
        return {
            singleSelection: true,
            primaryKey: "regionName",
            text: "Select Brand Site",
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            maxHeight: 200,
            escapeToClose: true,
            lazyLoading: true,
            tagToBody: false
        };
    }

    public static getBrandSettings() {
        return {
            singleSelection: true,
            text: "Select Brand",
            labelKey: "brandName",
            primaryKey: "brandName",
            enableSearchFilter: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            enableFilterSelectAll: false,
            lazyLoading: true
        }
    }
    public static getBrandsWithPrimaryKeySettings() {
        return {
            singleSelection: true,
            text: "Select Brand",
            labelKey: 'brandName',
            primaryKey: 'brandName',
            enableSearchFilter: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            escapeToClose: true,
            lazyLoading: true,
            enableFilterSelectAll: false
        };
    }
    public static getBrandSelectUnselectSettings(isDisabled: boolean = false) {
        return {
            singleSelection: false,
            text: "All Brand Site",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            classes: 'ddCustomClass custom-class',
            badgeShowLimit: 1,
            disabled: isDisabled,
            maxHeight: 200,
            lazyLoading: true
        };
    }
    public static getSSBrandSitesSettings(isDisabled: boolean = false) {
        return {
            singleSelection: true,
            text: "All Brand Sites",
            enableCheckAll: false,
            enableSearchFilter: false,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            disabled: isDisabled,
            lazyLoading: true,
            noDataLabel: "No Data Available"
        }
    }
    public static getSSAllBrandSitesSettings() {
        return {
            singleSelection: true,
            text: "All Brand Sites",
            enableSearchFilter: true,
            classes: "ddCustomClass custom-class",
            badgeShowLimit: 1,
            maxHeight: 200,
            lazyLoading: true,
            disabled: true,
            noDataLabel: "No Data Available"
        }
    }

    public static getPIETypeSettings(isDisabled: boolean = false, maxHeight: number = 80) {
        return {
            singleSelection: true,
            text: "Select PIE Type",
            badgeShowLimit: 1,
            maxHeight: maxHeight,
            disabled: isDisabled,
            lazyLoading: true,
        };
    }

    public static getPermissionGroupSettings() {
        return {
            singleSelection: true,
            text: "Select Permission Group",
            badgeShowLimit: 1,
            maxHeight: 200,
            enableSearchFilter: true,
            lazyLoading: true,
            enableFilterSelectAll: false,
            tagToBody: false
        };
    }

    public static getUserRoleSettings() {
        return {
            singleSelection: true,
            text: "Select User Role",
            badgeShowLimit: 1,
            maxHeight: 200,
            classes: "ddCustomClass custom-class",
            lazyLoading: true,
            tagToBody: false
        };
    }

    public static getReviewTranslationSettings() {
        return {
            singleSelection: false,
            primaryKey: 'regionName',
            text: "Select Review Translation",
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableCheckAll: true,
            enableSearchFilter: false,
            classes: 'ddCustomClass',
            lazyLoading: true,
            badgeShowLimit: 1,
            maxHeight: 200,
            escapeToClose: true,
            tagToBody: false
        }
    }

    public static getProductDropdownSettings() {
        return {
            singleSelection: false,
            primaryKey: 'itemName',
            text: "All Products",
            enableCheckAll: true,
            selectAllText: "Select All",
            unSelectAllText: "UnSelect All",
            enableSearchFilter: true,
            badgeShowLimit: 1,
            classes: 'ddCustomClass',
            lazyLoading: true,
            escapeToClose: true,
            enableFilterSelectAll: false,
            tagToBody: false
        }
    }

    public static getCategoriesDropdownSettings() {
        return {
            singleSelection: false,
            primaryKey: 'itemName',
            text: "All Categories",
            ...MULTI_DROPDOWN_SETTINGS,
            enableCheckAll: true,
            enableSearchFilter: true,
            badgeShowLimit: 1,
            classes: 'ddCustomClass custom-class-example',
            lazyLoading: true,
            escapeToClose: true,
            enableFilterSelectAll: false,
            tagToBody: false
        }
    }

    public static getMarketSingleSelectionSettings() {
        return {
            singleSelection: true,
            primaryKey: 'regionName',
            text: "All Brand Site",
            enableCheckAll: false,
            enableSearchFilter: false,
            classes: 'ddCustomClass custom-class',
            lazyLoading: true,
            badgeShowLimit: 1,
            escapeToClose: true,
            tagToBody: false
        };
    }
    public static getMarketMultiSelectSettings() {
        return {
            singleSelection: false,
            primaryKey: 'regionName',
            text: "All Brand Site",
            ...MULTI_DROPDOWN_SETTINGS,
            enableSearchFilter: true,
            classes: 'ddCustomClass custom-class',
            badgeShowLimit: 1,
            lazyLoading: true,
            escapeToClose: true,
            tagToBody: false
        };
    }
    public static getBrandsSettings() {
        return {
            singleSelection: false,
            labelKey: 'brandName',
            primaryKey: 'brandCode',
            text: "All Brands",
            enableCheckAll: true,
            selectAllText:  "Select All",
            unSelectAllText:  "UnSelect All",
            enableSearchFilter: true,
            classes: 'ddCustomClass custom-class',
            badgeShowLimit: 1,
            lazyLoading: true,
            escapeToClose: true
        };
    }

    public static getSingleDropdownSettings(text: string) {
        return {
            text: text,
            tagToBody: false,
            ...SINGLE_DROPDOWN_SETTINGS
        }
    }


}