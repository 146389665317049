import * as moment from "moment";
import { ChartConfiguration } from "chart.js";

import { CurrentUser, UserProfileModel } from "../models/user-profile.model";
import { One2FiveConstant } from "src/app/shared/models/referance.model";

export class One2FiveUtils {
    public static objectClone(inputObj: any) {
        if (!inputObj) return {};
        return JSON.parse(JSON.stringify(inputObj))
    }
    public static parseString(inputObj: any) {
        return JSON.parse(inputObj);
    }
    public static stringifyObject(inputObj: any) {
        return JSON.stringify(inputObj);
    }
}

export function appendIdToDataStore(sourceList: any[]) {
    if (!sourceList) return [];
    if (sourceList.length == 0) return [];

    const destinationObj: any = [];
    let i = 0;
    sourceList.map(_source => {
        let tempObj = { ..._source };
        tempObj.id = i++;
        destinationObj.push(tempObj);
    });
    return One2FiveUtils.objectClone(destinationObj);
}

export function getUserProfileDetails(): UserProfileModel {
    return One2FiveUtils.parseString(sessionStorage.getItem("currentUser"))?.userProfile;
}

export function getCompanyName(): string {
    return getUserProfileDetails()?.companyName ?? "MSM Digital";
}

export function getCurrentUser(): CurrentUser {
    return One2FiveUtils.parseString(sessionStorage.getItem("currentUser"));
}

export function delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

export function isEmptyObject(_inputObj: any) {
    return Object.keys(_inputObj).length == 0;
}

export function getHeaderPayload(): any {
    return {
        token: getToken(),
        company: getCompanyName()
    };
}
export function getUserName() {
    const currentUser = sessionStorage.getItem("currentUser");
    let userName = currentUser ? One2FiveUtils.parseString(currentUser).userName : "";
    return userName;
}

export function getCurrentUserRole() {
    const currentUser = sessionStorage.getItem("currentUser");
    let role = currentUser ? One2FiveUtils.parseString(currentUser).role : "";
    return role;
}

export function getToken() {
    const currentUser = sessionStorage.getItem("currentUser");
    let passKey = currentUser ? One2FiveUtils.parseString(currentUser).token : "";
    return passKey;
}

export function validateResponse<T>(response: T) {
    if (response)
        return response;
    response = {
        HasErrors: true,
        Errors: [
            { Code: "00", Message: "Server could not handle the request." },
        ]
    } as any;
    return response;
}

export function downloadFile(fileResponse: any, fileName: string, fileformat: string) {
    const url = window.URL.createObjectURL(fileResponse);
    const a = document.createElement("a");
    document.body.appendChild(a);
    a.setAttribute("style", "display: none");
    a.href = url;
    a.download = fileName + new Date().toDateString().replace(/ /g, "_") + "." + fileformat;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
}

export function getTodaysDate() {
    return new Date(Date.now());
}
export function getTodaysDay() {
    return getTodaysDate().getDate();
}
export function getCurrentMonth() {
    return getTodaysDate().getMonth() + 1;
}
export function getFullYear(): any {
    return getTodaysDate().getFullYear();
}


export function isEmpty(text: string): boolean {
    return text == null || text.match(/^\s*$/) !== null;
}
export function isSpacePresent(text: string): boolean {
    return text == null || text.match(/^[/^\S*$/]*$/) == null;
}
export function isSpecialCharAndNumberFound(text: string): boolean {
    return text == null || text.match(/[^a-zA-Z ]/) == null;
}
export function onlyNumbersAllowed(text: string): boolean {
    return text == null || text.match(/[^0-9]/) == null;
}


export function getDateRangePickerOptions(): any {
    const currentDate = new Date(Date.now());
    const currentMonth = currentDate.getMonth() + 1;

    let settings = {
        locale: {
            format: 'YYYY-MM-DD',
            cancelLabel: One2FiveConstant.Clear
        },
        autoUpdateInput: false,
        alwaysShowCalendars: false,
        maxDate: currentDate.getFullYear() + '-' + currentMonth + '-' + currentDate.getDate(),
        ranges: {
            Today: [moment(), moment()],
            'Last 7 Days': [moment().subtract(6, 'day'), moment()],
            'Last 15 Days': [moment().subtract(14, 'day'), moment()],
            'Last 30 Days': [moment().subtract(29, 'day'), moment()]
        },
        skipCSS: true
    };
    return settings;
}


export function reviewDetailsConfiguration(chartText: string = "", textPosition: any = "left", isResponsive: boolean = true) {
    let pDoughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        responsive: isResponsive,
        maintainAspectRatio: false,
        backgroundColor(ctx, _options) {
            if (ctx.dataIndex == 0)
                return 'lightBlue';
            else if (ctx.dataIndex == 1)
                return 'darkBlue';
            else if (ctx.dataIndex == 2)
                return 'lightGrey';
            else if (ctx.dataIndex == 3)
                return 'lightRed';
            else if (ctx.dataIndex == 4)
                return 'darkRed';
            else if (ctx.dataIndex == 5)
                return 'darkGrey';
            return 'rgb(196, 196, 196, 1)';
        },
        plugins: {
            title: {
                display: true,
                text: chartText,
                position: textPosition
            },
            legend: {
                position: "right",
                maxWidth: 300,
                display: true,
                labels: {
                    boxWidth: 25,
                    usePointStyle: true
                }
            }
        }
    }
    return pDoughnutChartOptions;
}


export function reviewStatisticsConfiguration(chartText: string = "", textPosition: any = "left", isResponsive: boolean = true) {
    let pDoughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        responsive: isResponsive,
        maintainAspectRatio: false,
        backgroundColor(ctx, _options) {
            if (ctx.dataIndex == 0)
                return 'rgb(77,166,255)';
            else if (ctx.dataIndex == 1)
                return 'darkRed';
            return 'rgb(196, 196, 196, 1)';
        },
        plugins: {
            title: {
                display: true,
                text: chartText,
                position: textPosition
            },
            legend: {
                position: "right",
                maxWidth: 300,
                display: true,
                labels: {
                    boxWidth: 25,
                    usePointStyle: true
                }
            }
        }
    }
    return pDoughnutChartOptions;
}

export function chartDoughnutConfiguration(chartText: string = "", textPosition: any = "left", isResponsive: boolean = true) {
    let pDoughnutChartOptions: ChartConfiguration<'doughnut'>['options'] = {
        responsive: isResponsive,
        maintainAspectRatio: false,
        animation: {
            duration: 2000,
        },
        backgroundColor(ctx, _options) {
            if (ctx.dataIndex == 0)
                return 'rgb(160, 203, 232, 1)';
            else if (ctx.dataIndex == 1)
                return 'rgba(225, 87, 89, 1)';
            else if (ctx.dataIndex == 2)
                return 'rgba(255,140,0)';
            else if (ctx.dataIndex == 3)
                return 'rgba(126,211,33)';
            return 'rgb(196, 196, 196, 1)';
        },
        plugins: {
            title: {
                display: true,
                text: chartText,
                position: textPosition
            },
            legend: {
                position: "bottom",
                maxWidth: 400,
                maxHeight: 400,
                display: true,
                labels: {
                    boxWidth: 25,
                    usePointStyle: true
                }
            }
        }
    }
    return pDoughnutChartOptions;
}


export function doughnutChartTextCenterPlugins(that: any = null, chatText: string = "") {
    let pluginOptions = [];
    if (that) {
        pluginOptions.push(
            {
                beforeDraw(chart: any) {
                    const ctx = chart.ctx;
                    const centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
                    const centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
                    ctx.fillStyle = '#444444';
                    ctx.fillText(chatText, centerX, centerY);
                }
            }
        )
    };
    pluginOptions.push({
        afterDraw(chart: any) {
            const ctx = chart.ctx;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            const fontSizeToUse = 18;
            ctx.font = fontSizeToUse + 'px Arial';
            ctx.fillStyle = 'black';

            // Draw text in center
            if (chart.data.datasets.length > 0) {
                const totalReviews = chart.data.datasets[0].data.reduce((_data: number, value: number) => _data += value, 0);
                if (totalReviews > 0) {
                    const totalCountLabelPadding = that ? 20 : 10;
                    ctx.fillText(totalReviews.toString(), centerX, centerY - totalCountLabelPadding);
                }
            }
        }
    });

    return pluginOptions;
}

export function isObject(value: any): boolean {
    return value !== null && typeof value === 'object';
}