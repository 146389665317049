import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { One2FiveUtils } from './one2five-utils';
import { CommonUtilService } from './common-util.service';
import { AccessControlService } from './access-control.service';
import { CurrentUser, UserAccountModel, UserProfileModel } from '../models/user-profile.model';
import { AuthenticationService } from '../authentication/authentication.service';
import { One2FiveLoadingService } from 'src/app/shared/components/app-loader/loader.service';

@Injectable({
	providedIn: 'root',
})
export class SecurityService {

	constructor(private router: Router,
		private authenticationService: AuthenticationService,
		private accessControlService: AccessControlService,
		private commonUtilService: CommonUtilService) {
		this.listenToLogoutuser();
	}

	listenToLogoutuser() {
		this.commonUtilService.logoutUser.subscribe(() => this.logoutUser());
	}

	checkIfUserLoggedIn() {
		return !!sessionStorage.getItem('currentUser');
	}

	getUserDetails() {
		return One2FiveUtils.parseString(sessionStorage.getItem('currentUser')) as CurrentUser;
	}

	logoutUser() {
		One2FiveLoadingService.show();
		this.authenticationService.logoutUser().subscribe(
			() => this.checkInternalOrExternalLogin(),
			() => this.checkInternalOrExternalLogin()
		);
	}

	checkInternalOrExternalLogin() {
		const currentUserSessionStorage = sessionStorage.getItem('currentUser');
		const currentUser = One2FiveUtils.parseString(currentUserSessionStorage) as CurrentUser;
		this.clearDataOnLogout();
		currentUser && currentUser.isExternalLogin !== false
			? this.logoutForExternalLogin(currentUser)
			: this.logoutForInternalLogin();
	}

	logoutForInternalLogin() {
		this.router.navigate(['/login']);
	}

	logoutForExternalLogin(currentUser: CurrentUser) {
		const historyURL = (currentUser.isExternalLogin as string).split('?')[0];
		window.location.href = historyURL;
	}

	clearDataOnLogout() {
		sessionStorage.clear();
		const locale: any = localStorage.getItem("currentLanguage") ? localStorage.getItem("currentLanguage") : 'en';
		localStorage.clear();
		localStorage.setItem("currentLanguage", locale.toLowerCase());
		this.accessControlService.isLoggedIn = false;
		this.accessControlService.permissionGroup = null;
		One2FiveLoadingService.hide();
	}

	externalUserLoginStoreData(url: string) {
		const urlParams = decodeURIComponent(url.split("?")[1]).split("&");

		let historyURL = "";
		let su = "";
		let token = "";

		let userProfileModel = new UserProfileModel;
		let userAccountModel = new UserAccountModel;
		urlParams.forEach((data) => {
			if (data.split("=")[0] === "historyURL") {
				historyURL = data.split("=")[1];
			}
			if (data.split("=")[0] === "su") {
				su = data.split("=")[1];
			}
			if (data.split("=")[0] === "token") {
				token = data.split("=")[1];
			}
			if (data.split("=")[0] === "userName") {
				userAccountModel.userName = data.split("=")[1];
			}
			if (data.split("=")[0] === "userRole") {
				userAccountModel.userRole = data.split("=")[1];
			}
			if (data.split("=")[0] === "city") {
				userProfileModel.city = data.split("=")[1];
			}
			if (data.split("=")[0] === "companyName") {
				userProfileModel.companyName = data.split("=")[1];
			}
			if (data.split("=")[0] === "email") {
				userProfileModel.email = data.split("=")[1];
			}
			if (data.split("=")[0] === "firstName") {
				userProfileModel.firstName = data.split("=")[1];
			}
			if (data.split("=")[0] === "lastName") {
				userProfileModel.lastName = data.split("=")[1];
			}
			if (data.split("=")[0] === "imagePath") {
				userProfileModel.imagePath = data.split("=")[1];
			}
			if (data.split("=")[0] === "salutation") {
				userProfileModel.salutation = data.split("=")[1];
			}
			if (data.split("=")[0] === "state") {
				userProfileModel.state = data.split("=")[1];
			}
			if (data.split("=")[0] === "street") {
				userProfileModel.street = data.split("=")[1];
			}
			if (data.split("=")[0] === "streetNo") {
				userProfileModel.streetNo = data.split("=")[1];
			}
			if (data.split("=")[0] === "userNickname") {
				userProfileModel.userNickname = data.split("=")[1];
			}
			if (data.split("=")[0] === "zipcode") {
				userProfileModel.zipcode = data.split("=")[1];
			}
		});

		localStorage.setItem("lastAction", Date.now().toString());
		sessionStorage.setItem(
			"currentUser",
			One2FiveUtils.stringifyObject({
				token: token,
				su: su,
				isExternalLogin: historyURL + "?" + true,
				userName: userAccountModel.userName,
				role: userAccountModel.userRole,
				userProfile: {
					city: userProfileModel.city,
					companyName: userProfileModel.companyName,
					email: userProfileModel.email,
					firstName: userProfileModel.firstName,
					imagePath: userProfileModel.imagePath,
					lastName: userProfileModel.lastName,
					salutation: userProfileModel.salutation,
					state: userProfileModel.state,
					street: userProfileModel.street,
					streetNo: userProfileModel.streetNo,
					userNickname: userProfileModel.userNickname,
					zipcode: userProfileModel.zipcode
				}
			})
		);
		this.accessControlService.onIsLoggedIn(true);
	}

}
