export const ConstantsService = {
	_defaultThemeColor: '#03A9F4',
	_defaultTextColor: '#ffffff',
	_defaultSecondaryColor: '#006694',
	_defaultLink: '/elx-frontend/src/assets/images/Electrolux/favicon.png',
	_defaultDocumentTitle: 'One2Five',

	NEWS_AND_SUPPORT: 'News and Support',
	RATINGS_AND_REVIEWS: 'Review management',
	QUESTION_MODERATION: 'Question Moderation',
	DASHBOARD: 'Dashboard',
	PRODUCT_COVERAGE: 'Product Coverage',
	PIE_CONVERSION: 'PIE Conversion',
	GLOBAL_COMPARISION: 'Global Comparision',
	REPORT_SCHEDULING: 'Report Scheduling',
	PLATFORM_STATUS: 'Platform Status',
	VOUCHER_CAMPAIGN: 'Voucher Campaign',
	MANAGE_ACCOUNTS: 'Manage Accounts',
	AUTOMATED_BADGING: 'Automated Badging',
	PIE_SETUP: 'PIE Setup',
	MANAGE_MARKETS: 'Manage Markets',
	MANAGE_RETAILERS: 'Manage Retailers',
	MANAGE_PERMISSIONS: 'Manage Permissions',
	CHANGE_THEME: 'Change Theme',
	MAILING_STATISTICS: 'Mailing Statistics',
	CAMPAIGNING_STATISTICS: 'Campaigning Statistics',
	VOUCHER_CAMPAIGNING_STATISTICS: 'Voucher-Campaign Statistics',
	MODERATION_VERIFICATION_STATISTICS: 'Moderation & Verification',
	MODERATION_STATISTICS: 'Moderation Statistics',
	VERIFICATION_STATISTICS: 'Verification Statistics',
	USER_REPORT_STATISTICS: 'User Statistics Report',
	SPECIAL_PERMISSIONS: 'Special Permission',
	REVIEW_UPLOAD: 'Review Upload',
	BADGE_UPDATE: 'Badge Update',
	STATISTICS: 'Statistics',
	INCLUDE_SENSITIVE_DATA: "Include Sensitive Data",
	MULTI_LANGUAGE_SETUP: 'Multi Language Setup',
	AUTO_MODERATION: 'Auto Moderation',
	PRODUCTS_REVIEWS: 'Product Reviews',
	SERVICES_REVIEWS: 'Service Reviews',
	PRODUCTS_OVERVIEW: 'Product Overview',
	SERVICE_OVERVIEW: 'Service Overview',
	CREATE_CLIENT: 'Create Client',

	Moderator: "Moderator",
	CountryManager: "Country Manager",
	MSM: "MSM",
	asc: "asc",
	desc: "desc",
	VerficationPending: "Verfication Pending",
	regular: "regular",
	vouchersetting: "voucher setting",
	emailsetting: "email setting",
	newsandSupport: "News and Support"
}
