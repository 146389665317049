<div class="modal-dialog modal-md">
  <div class="modal-content">
    <div class="modal-header"
      [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
      <div></div>
      <div class="modalHeaderTitle">
        {{ 'Change Password' | translate}}
      </div>
      <button class="btn"
        [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
        (click)="onCloseWindowClick()">
        <span class="icon icon-cancel"></span>
      </button>
    </div>

    <div class="modal-body">
      <div class="row mt-1 AI_C">
        <div class="col-md-12 col-lg-12 p-4">
          <strong>{{ "Note" | translate }}:</strong>
          ({{ "Password should contain" | translate }} <strong>{{ "atleast" | translate }}</strong>
          {{ "One Uppercase letter" | translate }},
          {{ "One Lowercase letter" | translate }},
          {{ "One Number" | translate }}, {{ "Minimum of" | translate }} 6 {{
          "characters" | translate }}.)
          <br />
        </div>
      </div>
      <div class="row mt-p5 AI_C">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-10 col-sm-10">
          <input class="w-100 form-control" type="password" name="" placeholder="{{ 'Old Password' | translate }}"
            [(ngModel)]="changePassword.oldPassword" />
          <div *ngIf="isIncorrectOldPassword" class="isIncorrectOldPassword">
            {{ "Incorrect Old Password" | translate }}
          </div>
        </div>
        <div class="col-md-1 col-sm-1"></div>
      </div>
      <div class="row mt-p5 AI_C">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-10 col-sm-10">
          <input class="w-100 form-control" type="password" name="" placeholder="{{ 'New Password' | translate }}"
            [(ngModel)]="changePassword.newPassword" />
        </div>
        <div class="col-md-1 col-sm-1"></div>
      </div>

      <div class="row mt-p5 AI_C">
        <div class="col-md-1 col-sm-1"></div>
        <div class="col-md-10 col-sm-10">
          <input class="w-100 form-control" type="password" name="" placeholder="{{ 'Confirm Password' | translate }}"
            [(ngModel)]="changePassword.confirmPassword" />
        </div>
        <div class="col-md-1 col-sm-1"></div>
      </div>
    </div>

    <div class="modal-footer mt-1 gap-4" [class.JC_SB]="errorMessage">
      <ng-container *ngIf="errorMessage">
        <h1 class="errorMsg w-75">{{ errorMessage | translate }}</h1>
      </ng-container>
      <button type="button" class="btn btn-primary"
        [disabled]="changePassword.oldPassword == '' || changePassword.newPassword == '' || changePassword.confirmPassword == ''"
        [class.disabled]="changePassword.oldPassword == '' || changePassword.newPassword == '' || changePassword.confirmPassword == ''"
        [ngStyle]="{ 'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor,
        color: applyThemeService.$textColor }" (click)="onSubmitChangePassword()">
        {{ "BUTTONS.Submit" | translate }}
      </button>
    </div>
  </div>
</div>