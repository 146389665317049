<div class="modal-dialog modal-lg">
    <div class="modal-content">
        <div class="modal-header"
            [ngStyle]="{ 'background-color': applyThemeService.$themeColor, color: applyThemeService.$textColor }">
            <div></div>
            <div class="modalHeaderTitle">
                {{ "MENUITEMS.Upload Reviews" | translate}}
            </div>
            <button class="btn"
                [ngStyle]="{ 'background-color': applyThemeService.$secondaryColor, color: applyThemeService.$textColor }"
                (click)="onCloseReviewUplodWindow()">
                <span class="icon icon-cancel"></span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row mt-1 AI_C">
                <div class="col-md-1 col-lg-1"></div>
                <div class="col-md-3 col-lg-3">
                    {{"Moderation Status" | translate}} :
                </div>
                <div class="col-md-6">
                    <angular2-multiselect [settings]="moderationStatusDropdownSettings" [data]="reviewStatus"
                        [(ngModel)]="reviewSelectedStatuses">
                    </angular2-multiselect>
                </div>
                <div class="col-md-2"></div>
            </div>
            <div class="row mt-p5 AI_C">
                <div class="col-md-1 col-lg-1"></div>
                <div class="col-md-3 col-lg-3">
                    {{ "Brand Site" | translate}} :
                </div>
                <div class="col-md-6">
                    <angular2-multiselect [settings]="marketDropdownSettings" [data]="arrMarketList"
                        [(ngModel)]="marketListSelectedItems">
                    </angular2-multiselect>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-p5 AI_C">
                <div class="col-md-1 col-lg-1"></div>
                <div class="col-md-3 col-lg-3">
                    {{"Badges" | translate}} :
                </div>
                <div class="col-md-6">
                    <angular2-multiselect [settings]="badgeSettings" [data]="badgeTemplateList"
                        [(ngModel)]="badgeSelectedList" (onSelect)="onBadgeSelect($event)">
                    </angular2-multiselect>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-p5 AI_C">
                <div class="col-md-4 col-lg-4"></div>
                <div class="col-md-6 TA_C">
                    <button type="button" class="btn btn-default importDummyBtn w-100">
                        <a download="Import Dummy" href="/assets/files/Import_Dummy.xlsx" target="_blank">
                            {{"BUTTONS.Download" | translate}} {{"Dummy" | translate}} {{"File" | translate}}
                            <span class="icon icon-download"></span>
                        </a>
                    </button>
                </div>
                <div class="col-md-2"></div>
            </div>

            <div class="row mt-p5 AI_C">
                <div class="col-md-1 col-lg-1"></div>
                <div class="col-md-9 col-lg-9 importDummyBtn">
                    <ng-container *ngIf="isFileValid else showInvalidFile">
                        <span class="greenColor">{{"SUCCESS.File has been uploaded successfully" | translate}}!</span>
                        <div>{{"or" | translate}}</div>
                        <button type="button" class="btn btn-primary customBtn retryBtn"
                            [ngStyle]="{ 'background-color': applyThemeService.$themeColor, 'border-color': applyThemeService.$themeColor, color: applyThemeService.$textColor  }"
                            (click)="reUpload()">
                            {{"BUTTONS.Retry" |translate }}
                        </button>
                    </ng-container>
                    <ng-template #showInvalidFile>
                        <div ng2FileDrop (onFileDrop)="dropped($event)" class="dropzone">
                            <span>{{"Click to add or Drop a file here" | translate}} </span>
                            <span class="icon icon-upload"></span>
                            <input type="file" (change)="onSelectFile($event)" class="H-36P opacity-0"
                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" />
                        </div>
                        <span class="redColor">*{{"ERRORS.Moderation Status has to be present in the upload file" |
                            translate}}
                        </span>
                    </ng-template>
                </div>
                <div class="col-md-2 col-lg-2"></div>
            </div>
        </div>
        <div *ngIf="accessControls.isCreate" class="modal-footer mt-1">
            <button type="button" class="btn btn-primary" [ngStyle]="{ 'background-color': applyThemeService.$themeColor,'border-color': applyThemeService.$themeColor,
                color: applyThemeService.$textColor}" (click)="uploadFile()">
                {{"BUTTONS.Submit" |translate }}
            </button>
        </div>
    </div>
</div>