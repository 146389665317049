import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, NgModule, OnChanges, Output, SimpleChanges, ViewContainerRef } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ColorPickerModule } from 'ngx-color-picker';

import { AccessControls } from 'src/app/core/models/user.permission.model';
import { AccessControlService } from 'src/app/core/services/access-control.service';
import { CommonUtilService } from 'src/app/core/services/common-util.service';
import { getToken } from 'src/app/core/services/one2five-utils';
import { One2FiveLoadingService } from 'src/app/shared/components/app-loader/loader.service';
import { CustomTranslationModule } from '../../custom-translation.module';
import { MessagesService } from 'src/app/shared/components/messages/messages.service';
import { ApplyThemeService } from 'src/app/shared/services/apply-theme.service';
import { ConstantsService } from 'src/app/shared/services/constants.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MessageItemType } from 'src/app/shared/components/messages/message.model';
import { ThemeTemplateModel } from 'src/app/shared/models/theme.model';

@Component({
  selector: 'app-change-theme',
  templateUrl: './change-theme.component.html',
  styleUrls: ['./change-theme.component.scss']
})
export class ChangeThemeComponent implements OnChanges {
  @Input("ready2LoadWindow") ready2LoadWindow: boolean = false;
  @Output("closeThemeWindow") closeThemeWindow = new EventEmitter();

  accessControls = new AccessControls;
  themeTemplateModel: ThemeTemplateModel;
  formData!: FormData;

  logoImage: any = null;
  logoImageSmall: File | any = null;
  favIcon: File | any = null;
  privacyStatement: File | any = null;
  aboutUs: File | any = null;
  logoPlaceholder: any = null;

  isLargeLogo = false;
  isFavicon = false;
  isSmallLogo = false;
  isPrivacyStatement = false;
  isAboutUs = false;
  largeLogoPath = "";
  smallLogoPath = "";
  faviconPath = "";
  privacyStatementPath = "";
  aboutUsPath = "";

  constructor(private translate: TranslateService,
    private dataService: DataService,
    public vcRef: ViewContainerRef,
    public applyThemeService: ApplyThemeService,
    private accessControlService: AccessControlService,
    public commonUtilService: CommonUtilService) {
    this.themeTemplateModel = new ThemeTemplateModel;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if(!this.ready2LoadWindow) return;

    this.commonUtilService.languageSubjectRef$.subscribe(element => {
      this.translate.use(element);
    });
    this.themeTemplateModel.themeColor = this.applyThemeService.$themeColor;
    this.themeTemplateModel.textColor = this.applyThemeService.$textColor;
    this.themeTemplateModel.secondaryColor = this.applyThemeService.$secondaryColor;
    this.themeTemplateModel.welcomeTitle = this.applyThemeService.$WelcomeTitle;
    this.themeTemplateModel.welcomeText = this.applyThemeService.$welcomeText;
    this.themeTemplateModel.copyrightLabel = this.applyThemeService.$copyrightText;
    this.themeTemplateModel.documentTitle = this.applyThemeService.$documentTitle;

    if (this.applyThemeService.$largeLogo) {
      this.isLargeLogo = true;
      this.largeLogoPath = this.applyThemeService.$largeLogo;
    }
    if (this.applyThemeService.$smallLogo) {
      this.isSmallLogo = true;
      this.smallLogoPath = this.applyThemeService.$smallLogo;
    }
    if (this.applyThemeService.$favicon) {
      this.isFavicon = true;
      this.faviconPath = this.applyThemeService.$favicon;
    }
    if (this.applyThemeService.$privacyStatement) {
      this.isPrivacyStatement = true;
      this.privacyStatementPath = this.applyThemeService.$privacyStatement;
    }
    if (this.applyThemeService.$aboutUs) {
      this.isAboutUs = true;
      this.aboutUsPath = this.applyThemeService.$aboutUs;
    } 
    this.accessControls = this.accessControlService.configAccess(ConstantsService.CHANGE_THEME);   
  }

  onCloseChangeThemeWindow() {
    this.closeThemeWindow.emit();
  }

  onClickThemeColorOkBtn(event: any) {
    this.themeTemplateModel.themeColor = event;
    this.themeTemplateModel.textColor = this.colorToHexaDecimal(event);
  }
  onClickTextColorOkBtn(event: any) {
    if (this.themeTemplateModel.themeColor === event) {
      this.textColorValidationMsg();
    } else if (this.themeTemplateModel.secondaryColor === event) {
      this.textColorValidationMsg();
    } else {
      this.themeTemplateModel.textColor = event;
    }
  }
  onClickSecondaryColorOkBtn(event: any) {
    if (this.themeTemplateModel.themeColor === event) {
      this.secondaryColorValidationMsg();
    } else if (this.themeTemplateModel.textColor === event) {
      this.secondaryColorValidationMsg();
    } else {
      this.themeTemplateModel.secondaryColor = event;
    }
  }

  onChangeThemeColor(_sliderEvent: any) {
    this.themeTemplateModel.themeColor = _sliderEvent.color;
    this.themeTemplateModel.textColor = this.colorToHexaDecimal(this.themeTemplateModel.themeColor);
  }

  onChangeTextColor(_sliderEvent: any) {
    if (this.themeTemplateModel.themeColor === _sliderEvent.color) {
      this.textColorValidationMsgOnChangeSecondaryColor();
    } else if (this.themeTemplateModel.secondaryColor === _sliderEvent.color) {
      this.textColorValidationMsgOnChangeSecondaryColor();
    } else {
      this.themeTemplateModel.textColor = _sliderEvent.color;
    }
  }
  onChangeSecondaryColor(_sliderEvent: any) {
    if (this.themeTemplateModel.themeColor === _sliderEvent.color) {
      this.secondaryColorValidationMsgOnChangeSecondaryColor();
    } else if (this.themeTemplateModel.textColor === _sliderEvent.color) {
      this.secondaryColorValidationMsgOnChangeSecondaryColor();
    } else {
      this.themeTemplateModel.secondaryColor = _sliderEvent.color;
    }
  }

  onRemoveFile(value: any) {
    switch (value) {
      case "isLargeLogo":
        this.isLargeLogo = false;
        break;
      case "isSmallLogo":
        this.isSmallLogo = false;
        break;
      case "isFavicon":
        this.isFavicon = false;
        break;
      case "isPrivacyStatement":
        this.isPrivacyStatement = false;
        break;
      case "isAboutUs":
        this.isAboutUs = false;
        break;
    }
  }

  onSetNewThemeClick() {
    const postData = new FormData();
    if (this.logoImageSmall) {
      postData.append("smallLogo", this.logoPlaceholder);
    }
    if (this.logoImage) {
      postData.append("largeLogo", this.logoImage);
    }
    if (this.favIcon) {
      postData.append("favicon", this.favIcon);
    }
    postData.append("copyrightLabel", this.themeTemplateModel.copyrightLabel);
    postData.append("secondaryColor", this.themeTemplateModel.secondaryColor);
    postData.append("textColor", this.themeTemplateModel.textColor);
    postData.append("themeColor", this.themeTemplateModel.themeColor);
    postData.append("welcomeText", this.themeTemplateModel.welcomeText);
    postData.append("welcomeTitle", this.themeTemplateModel.welcomeTitle);
    if (this.privacyStatement) {
      postData.append("privacyStatement", this.privacyStatement);
    }
    if (this.aboutUs) {
      postData.append("aboutUs", this.aboutUs);
    }
    postData.append("documentTitle", this.themeTemplateModel.documentTitle);
    postData.append("token", getToken());

    this.dataService.changeTheme(postData).subscribe((data: any) => {
      One2FiveLoadingService.hide();
      if (data["HasErrors"]) {
        MessagesService.popupAlertAlertMessages(data["Errors"][0].Message);
        return;
      }
      window.location.reload();
    }, (error) => {
      MessagesService.showFailureAlert(error);
      One2FiveLoadingService.hide();
    });
  }

  colorToHexaDecimal(hex: any) {
    if (!hex) {
      throw new Error(this.commonUtilService.translateMessage("ERRORS.Invalid HEX color"));
    }
    if (hex.indexOf("#") === 0) {
      hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
      throw new Error(this.commonUtilService.translateMessage("ERRORS.Invalid HEX color"));
    }
    // invert color components
    const r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
      g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
      b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return "#" + this.addZeroPadding(r, 0) + this.addZeroPadding(g, 0) + this.addZeroPadding(b, 0);
  }

  addZeroPadding(str: any, len: any) {
    len = len || 2;
    const zeros = new Array(len).join("0");
    return (zeros + str).slice(-len);
  }
  
  onLargeFileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      const reader = new FileReader();
      let that = this;
      reader.onloadend = function () {
        if (that.validateUploadFile(file)) {
          that.logoImage = file;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onSmallFileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length == 0) return;

    const file: File = fileList[0];
    const fileReader = new FileReader();
    const that = this;
    fileReader.onloadend = () => {
      if (that.validateUploadFile(file)) {
        that.logoImageSmall = fileReader.result;
        that.logoPlaceholder = file;
      }
    };
    fileReader.readAsDataURL(file);
  }

  onFavIconImageChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length == 0) return;

    const file: File = fileList[0];
    const fileReader = new FileReader();
    const that = this;
    fileReader.onloadend = () => {
      if (that.validateUploadFile(file)) {
        that.favIcon = file;
      }
    };
    fileReader.readAsDataURL(file);
  }

  onPrivacyStatementChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length == 0) return;

    const file: File = fileList[0];
    const fileReader = new FileReader();
    const that = this;
    fileReader.onloadend = () => {
      if (that.validateUploadHTMLFile(file)) {
        that.privacyStatement = file;
      }
    };
    fileReader.readAsDataURL(file);
  }

  onAboutUsFileChange(event: any) {
    const fileList: FileList = event.target.files;
    if (fileList.length == 0) return;

    const file: File = fileList[0];
    const fileReader = new FileReader();
    const that = this;
    fileReader.onloadend = () => {
      if (that.validateUploadHTMLFile(file)) {
        that.aboutUs = file;
      }
    };
    fileReader.readAsDataURL(file);
  }

  onChangeToDefaultTheme() {
    this.themeTemplateModel.themeColor = this.applyThemeService.$themeColor;
    this.themeTemplateModel.textColor = this.applyThemeService.$textColor;
    this.themeTemplateModel.secondaryColor = this.applyThemeService.$secondaryColor;

    this.themeTemplateModel.welcomeTitle = this.applyThemeService.$WelcomeTitle;
    this.themeTemplateModel.welcomeText = this.applyThemeService.$welcomeText;
    this.themeTemplateModel.copyrightLabel = this.applyThemeService.$copyrightText;
    this.themeTemplateModel.documentTitle = this.applyThemeService.$documentTitle;

    if (this.applyThemeService.$largeLogo) {
      this.isLargeLogo = true;
      this.largeLogoPath = this.applyThemeService.$largeLogo;
    }
    if (this.applyThemeService.$smallLogo) {
      this.isSmallLogo = true;
      this.smallLogoPath = this.applyThemeService.$smallLogo;
    }
    if (this.applyThemeService.$favicon) {
      this.isFavicon = true;
      this.faviconPath = this.applyThemeService.$favicon;
    }
    if (this.applyThemeService.$privacyStatement) {
      this.isPrivacyStatement = true;
      this.privacyStatementPath = this.applyThemeService.$privacyStatement;
    }
    if (this.applyThemeService.$aboutUs) {
      this.isAboutUs = true;
      this.aboutUsPath = this.applyThemeService.$aboutUs;
    }
  }
  
  textColorValidationMsgOnChangeSecondaryColor() {
    MessagesService.alertMessages("ERRORS.Please select different text color", "", "Validation Alert", MessageItemType.Warning);
    this.themeTemplateModel.textColor = this.applyThemeService.$textColor;
  }
  secondaryColorValidationMsgOnChangeSecondaryColor() {
    MessagesService.alertMessages("ERRORS.Please select different secondary color", "", "Validation Alert", MessageItemType.Warning);
    this.themeTemplateModel.secondaryColor = this.applyThemeService.$secondaryColor;
  }

  validateUploadFile(file: any): boolean {
    if (!(file.type === "image/jpeg" || file.type === "image/x-png" || file.type === "image/png")) {
      MessagesService.alertMessages("Please upload image with correct extension.", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    if (file.size > 2000000) {
      MessagesService.alertMessages("File has to be below 2MB in size", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    return true;
  }

  validateUploadHTMLFile(file: any): boolean {
    if (file.type !== "text/html") {
      MessagesService.alertMessages("Please upload file with correct extension.", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    if (file.size > 2000000) {
      MessagesService.alertMessages("File has to be below 2MB in size", "", "Validation Alert", MessageItemType.Warning);
      return false;
    }
    return true;
  }

  textColorValidationMsg() {
    MessagesService.alertMessages("ERRORS.Please select different text color", "", "Validation Alert", MessageItemType.Warning);
  }

  secondaryColorValidationMsg() {
    MessagesService.alertMessages("ERRORS.Please select different secondary color", "", "Validation Alert", MessageItemType.Warning);
  }

}

@NgModule({
  declarations: [ChangeThemeComponent],
  imports: [CommonModule, FormsModule, ColorPickerModule, CustomTranslationModule],
  exports: [ChangeThemeComponent]
})
export class ChangeThemeModule { }