<div class="mainHeader fullWidth" [ngStyle]="{ 'background-color': applyThemeService.$themeColor }">
  <div class="logoDiv whiteColor inline-block" [ngStyle]="{
    'background-image': 'url(' + applyThemeService.$smallLogo + ')',
    '-webkit-filter': 'drop-shadow(4px 4px 5px rgba(0,0,0,0.5))'}">
  </div>
  <div class="f-right VAlignChildrenForHeader">
    <div *ngIf="isCompanyMenuAccess" class="marketList">
      <angular2-multiselect #companyMultiSelect [data]="companyList" [ngModel]="companyListSelectedItems"
        [settings]="companyDropdownSettings" (onSelect)="onCompanySelect($event, manageClientTemplateRef)"
        (onDeSelect)="onCompanyDeSelect($event)">
        <c-badge>
          <ng-template let-item="item">
            <label class="cr-pointer"
              style="margin: 0px; color: #000; max-width: 100%; font-weight: 400;">{{selectedCompanyName}}</label>
          </ng-template>
        </c-badge>
        <c-item>
          <ng-template let-item="item">
            <ng-container *ngIf="item.id == one2FiveConstant.newclient">
              <div class="cr-pointer FW-500 clsnew-client">
                {{ item.itemName }}
              </div>
            </ng-container>
            <ng-container *ngIf="item.id !== one2FiveConstant.newclient">
              <div class="cr-pointer D-F JC_SB MT-20M">
                <div class="W-15P">
                  <em *ngIf="selectedCompanyId == item.id" class="icon icon-right-mark F_L PT-4 blueColor"></em>
                  <em *ngIf="selectedCompanyId != item.id" class="F_L PT-4 blueColor"></em>
                </div>
                <div class="W-60P">
                  <label class="cr-pointer cls_ddlabel FW-500">
                    {{ item.itemName }}
                  </label>
                </div>
                <div class="W-15P">
                  <em *ngIf="clientAccessControls.isUpdate && loggedInUserCompany == item.itemName" title="Edit"
                    class="icon icon-edit F_R PT-4 clseditclientbtn"
                    (click)="onOpenClientDetails(item, manageClientTemplateRef)">
                  </em>
                  <em
                    *ngIf="!clientAccessControls.isUpdate || item.id == one2FiveConstant.newclient || loggedInUserCompany != item.itemName"></em>
                </div>
              </div>
            </ng-container>
          </ng-template>
        </c-item>
      </angular2-multiselect>
    </div>
    &nbsp;
    <div *ngIf="isMultiLanguageSetupAccess && languages" class="W-70">
      <angular2-multiselect [data]="languages" [(ngModel)]="languageCodeSelected" [settings]="languageCodeSettings"
        (onSelect)="onLanguageSelection($event)" (onDeSelect)="onLanguageDeSelection($event)">
        <c-badge>
          <ng-template let-item="item">
            <label class="cr-pointer" style="margin: 0px; color: #000; max-width: 100%; font-weight: 400;">{{
              item.itemName }}</label>
          </ng-template>
        </c-badge>
        <c-item>
          <ng-template let-item="item">
            <label class="cls_ddlabel" [title]="item.languageName">{{ item.itemName }}</label>
            <img [src]="item.path" alt="Not Found" [title]="item.languageName" class="cls_dditem" />
          </ng-template>
        </c-item>
      </angular2-multiselect>
    </div>

    <div *ngIf="show" class="marketList">
      <angular2-multiselect [data]="marketList" [(ngModel)]="marketListSelectedItems"
        [settings]="marketDropdownSettings" (onSelect)="onMarketSelect($event)" (onDeSelect)="onMarketDeSelect($event)"
        (onSelectAll)="onMarketSelectAll($event)" (onDeSelectAll)="onMarketDeSelectAll($event)">
      </angular2-multiselect>
    </div>
    <span class="menuOptions whiteColor inline-block font-weight" (click)="clickedInside($event)" [ngStyle]="{
        'background-color': profileMenuClick ? applyThemeService.$secondaryColor : applyThemeService.$themeColor,
        color: applyThemeService.$textColor
      }">
      <span class="paddingDP"></span>
      <span class="smallProfile" [style.backgroundImage]="'url(' + smallProfilePic + ')'">
      </span>
      {{ "Hi" | translate }}, {{ userProfile ? userProfile.userNickname : '' }}
      <span class="paddingArrow" [ngStyle]="{ transform: profileMenuClick ? 'rotate(90deg)' : 'rotate(0deg)' }">
        <span class="icon icon-right-arrow"></span>
      </span>
    </span>
  </div>
</div>

<app-profile-menu *ngIf="profileMenuClick" (editProfile)="onEditProfile(editProfileTemplateRef)"
  (changePassword)="onChangePassword(changePasswordTemplateRef)" (changeTheme)="onChangeTheme(changeThemeTemplateRef)"
  (logout)="onLogOut()">
</app-profile-menu>


<div class="modal fade" bsModal #editProfileTemplateRef="bs-modal" tabindex="-1" role="dialog">
  <app-edit-profile [isReady2Load]="loadEditProfile" (onCloseWindow)="closeEditProfile()"></app-edit-profile>
</div>


<div class="modal fade" bsModal #changePasswordTemplateRef="bs-modal" tabindex="-1" role="dialog">
  <app-change-password [isReady2Load]="loadChangePassword" (success)="changePassShowMsg()"
    (onCloseWindow)="closeChangePassword()">
  </app-change-password>
</div>


<div class="modal fade" bsModal #changeThemeTemplateRef="bs-modal" tabindex="-1" role="dialog">
  <app-change-theme [ready2LoadWindow]="loadChangeTheme" (closeThemeWindow)="closeChangeTheme()"
    (refresh)="refresh()"></app-change-theme>
</div>

<div *ngIf="changePassSuccess" class="alert alert-success successAlert" role="alert">
  {{ "Your password has changed successfully" | translate }}!
</div>

<app-update-email [ready2LoadUpdateEmailWindow]="loadUpdateEmailWindow"></app-update-email>

<div class="modal fade" bsModal #manageClientTemplateRef="bs-modal" tabindex="-1" role="dialog">
  <app-manage-client [ready2LoadWindow]="loadClientModal" [initialState]="initialState"
    (closeClientWindow)="onCloseClientModal()">
  </app-manage-client>
</div>